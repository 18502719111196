import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

import firebase from 'firebase/compat/app';
import {getAuth} from 'firebase/auth';

const config = {
  apiKey: "AIzaSyD9qHPHha76ZAew8hZ9ZQhePqs1YWBg8ns",
  authDomain: "sally-melbet.firebaseapp.com",
  projectId: "sally-melbet",
  storageBucket: "sally-melbet.appspot.com",
  messagingSenderId: "86057479700",
  appId: "1:86057479700:web:14eb08a64328a41c9f8101"
};

let app;

const initializeFirebase = () => {
  if (!firebase.apps.length) {
    app = firebase.initializeApp(config);
  }
};

initializeFirebase();

const auth = getAuth(app);
const firebaseAuth = firebase.auth(app);
const storage = firebase.storage(app);
const firestore = firebase.firestore(app);
const functions = firebase.functions(app);

export {app, firestore, storage, auth, firebaseAuth, functions};
