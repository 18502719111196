import { useContext, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, message, Alert, Spin } from 'antd';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import DataTable from '../../components/table/Index';
import { useCollection, useDoc } from '../../helpers/firestore';
// import { convertDateTimeFromTimestamp } from '../../helpers/utils';
import { WazMelbetModel } from '../../types';
import AccountAdd from './melbetAdd';
import { tableAddbuttons } from '../../helpers/theme_helper';

import { Routes } from '../../routes/route_helper';
import { UserContext } from '../../context/app.contexts';
const Dashboard = () => {

  const { user } = useContext(UserContext);
  // const [wazUser, wazUserLoading] = useDoc(`wazs/${user.uid}`);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [melbetIDs, melbetIDsLoading] = useCollection(
    `wazs/${user.uid}/depositAccounts/`,
    'createdAt',
  );

  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);
  const onClickDetail = (uid: string, melbetID: string) => {
 
      navigate(Routes.compilePath(Routes.deposits, { id: uid, melbetID: melbetID }));
 
  };

  const columns: ColumnsType<WazMelbetModel> = [
    {
      key: 'melbetID',
      title: 'Melbet ID -- Дансны дугаар',
      dataIndex: 'melbetID',
    },

    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      render: (_, record) => (
        <div className="flex gap-2">
          <Button

            size="small"
            type="primary"
            className="bg-success"
            onClick={() => {
              onClickDetail(record.uid, record.melbetID);
            }}
          >Цэнэглэх</Button>

        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      {!_.isEmpty(error) && <Alert message={error} type="error" />}
 
          <Spin spinning={melbetIDsLoading}>

            <DataTable
              tableName=""
              columns={columns}
              data={melbetIDs}
              loading={melbetIDsLoading}
              headerWidgers={tableAddbuttons('Melbet холбох', toggleModal)}
            />
            <AccountAdd uid={user.uid} open={open} toggle={toggleModal} />
          </Spin>

    </div>);
};

export default Dashboard;
