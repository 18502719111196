import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import ResetPass from '../pages/auth/ResetPass';
import Dashboard from '../pages/dashboard/Index';
import Deposit from '../pages/dashboard/deposit';

import {Routes} from './route_helper';

import NotFound from '../pages/NotFound';


const authProtectedRoutes = [
  {path: Routes.dashboard, component: Dashboard},
  {path: Routes.deposits, component: Deposit},
 
];

const initialRoute = [{path: '*', component: NotFound}];
const publicRoutes = [
  {path: Routes.login, component: Login},
  {path: Routes.register, component: Register},
  {path: Routes.forgot, component: ResetPass},
];




export {
  publicRoutes,
  initialRoute,
  authProtectedRoutes,
};
