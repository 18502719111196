import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, message } from 'antd';
import * as _ from 'lodash';

import { appOTP,appConfirmOTP,appUserRegister } from '../../helpers/firebase_functions';
import { OtpType } from '../../helpers/types';
import LogoImage from '../../assets/images/logo.png';
import { Routes } from '../../routes/route_helper';
import ResetPass from './ResetPass';

const Login = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [username, setUsername] = useState('');
  const [otp, setOTP] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [openOTP, setOpenOTP] = useState(false);
  const [open, setOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const toggleModal = () => setOpen(!open);

  const onSubmit = async () => {
    if (!validate()) return;
    try {
      setLoading(true);
      const res = await appOTP(username, OtpType.REGISTER);
      console.log(res)
      if(res.status || res.msg =="Баталгаажуулах код илгээсэн байна."){
        setOpenOTP(true)
        setLoading(false)
      }else{
        setLoading(false)
        messageApi.error(res.msg)
      }
      // navigate(Routes.dashboard);
    } catch (e: any) {
      setError(e.toString());
      setLoading(false);
    }
  };
  const onSubmitOTP = async () => {
    if (_.isEmpty(username)) return ;

    const payload ={phone:username, type:OtpType.REGISTER,otp_code:parseInt(otp)}
    try {
      setLoading(true);
      console.log(payload)
      const res = await appConfirmOTP(payload);
      console.log(res)
      if(res.status ){
        messageApi.success(res.msg)
        setShowLogin(true)
      }else{
        setLoading(false)
        messageApi.error(res.msg)
      }
      // navigate(Routes.dashboard);
    } catch (e: any) {
      setError(e.toString());
      setLoading(false);
    }
  };

  const validate = () => {
    if (_.isEmpty(username)) return false;
    return true;
  };

  return (
    <div className="flex items-center justify-center h-full">
         {contextHolder}
      <div className="card-container">
        <div className="border rounded-md border-gray-900 w-full sm:w-auto">
          <div className="dialog-header rounded-t-md">
            <div className="text-xl font-medium">🇲🇳 Melbet App  Баталгаажуулах код </div>
          </div>
          <div className="dialog-body">
            <div className="mt-4 flex justify-center">
              <img alt="logo" src={LogoImage} height={140} width="auto" />
            </div>
            <div className="dialog-content px-8">
              <Input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Утасны дугаар"
                className="mb-3"
                disabled={openOTP}
              />
              {openOTP && (
                <Input
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                  placeholder="SMS код"
                  className="mb-3"
                  disabled={showLogin}
                />
              )}
              <div className="error-text text-sm pt-4 text-error">{error}</div>
            </div>
          </div>
          <div className="dialog-footer rounded-b-md">
            {!openOTP && (
              <Button
                disabled={loading}
                className="w-full rounded-xl"
                type="primary"
                onClick={onSubmit}
              >
                Бүртгүүлэх
              </Button>
            )}
            {openOTP && !showLogin &&(
              <Button
                disabled={loading}
                className="w-full rounded-xl"
                type="primary"
                onClick={onSubmitOTP}
              >
                Баталгаажуулах
              </Button>
            )}
   {showLogin &&(
              <Button
             
                className="w-full rounded-xl"
                type="primary"
                onClick={()=>navigate(Routes.login)}
              >
                Нэвтрэх хэсэг очих
              </Button>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
