import {compile} from 'path-to-regexp';

export const Routes = {
  dashboard: '/',
  deposits: `/deposits/:id/:melbetID`,
  //auth
  login: '/login',
  register:'/register',
  forgot:'/forgot',
  notfound: '/notfound',
  compilePath(path: string, param: object) {
    const toPath = compile(path, {encode: encodeURIComponent});
    return toPath(param);
  },
};
