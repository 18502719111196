import { useContext, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';

import DataTable from '../../components/table/Index';
import {  useDoc,useCollectionWithMulti } from '../../helpers/firestore';
import { DepositModel } from '../../types';
import { Button,  Spin, Col, Row, message,Alert ,Tag } from 'antd';

import { UserContext } from '../../context/app.contexts';
import {checkKhanBank} from '../../helpers/firebase_functions'
import { StyleInfo } from 'antd/es/theme/util/genComponentStyleHook';
const Deposits = () => {
  const checkSecs= 20
  const [messageApi, contextHolder] = message.useMessage();
  const { id ,melbetID} = useParams()
  const { user } = useContext(UserContext);

  const [checkMsg, setCheckMsg] = useState('');
  const [isCheckDepo, setIsCheckDepo] = useState(false);
  const [checkTime, setCheckTime] = useState(0);
  const [bank, bankLoading] = useDoc('configs/wazDepositBank');
  const [config, configLoading] = useDoc(`wazsConfig/${id}`);
  const [deposits, depositsLoading] = useCollectionWithMulti(`deposits`,'createdAt',{field:'userID',op:'==',value:user.uid},{field:'depAcc',op:'==',value:id||''});
  const limitDesc =`Та эрхээ нэмэхийн тулд https://www.facebook.com/mnmelbet/ хаяг руу чат бичээрэй  Таны ID: ${user.uid} `
  // const onClickDetail = (uid: string) => {
  //   navigate(Routes.compilePath(Routes.deposits, { id: uid }));
  // };

  const checkDeposit=async()=>{
    if(isCheckDepo){
      messageApi.error(`${checkTime} секунтын дараа дахин шалгана уу`)
      return
    }
    setIsCheckDepo(true)
    setCheckTime(checkSecs)
    const result = await checkKhanBank(id||'');
    console.log(result)
    setCheckMsg(result.mgs)
    if(result.status){
      messageApi.info(result.msg)
    }else{
      messageApi.error(result.msg)
    }
  }
  useEffect(()=>{
    if (!checkTime) {
      setIsCheckDepo(false)
      return;
    }
    if(checkTime>0)
    setInterval(() => {
      setCheckTime(checkTime-1)
  }, 1000);
  else{

  }
  },[checkTime])

  const clipboardwriteText = (value: string) => {
    navigator.clipboard.writeText(value);
    messageApi.info('Амжилттай хуулагдлаа!')
  }
  const columns: ColumnsType<DepositModel> = [
    {
      key: 'amount',
      title: 'Цэнэглэлт дүн',
      dataIndex: 'amount',
      render: (amount: any) => {return numberWithCommas(amount.amount)}
    },
    {
      key: 'statusMsg',
      title: 'Төлөв',
      dataIndex: 'statusMsg',
      render: (statusMsg: string,record) => {
        if(record.status =='new'){
          return <Tag  color="processing"> {statusMsg}</Tag>
        }
        if(record.status =='review'){
          return <Tag  color="warning"> {statusMsg}</Tag>
        }
        if(record.status =='Done'){
          return <Tag  color="success"> {statusMsg}</Tag>
        }
        if(record.status =='MelError'){
          return <Tag color="error"> {statusMsg}</Tag>
        }
        

        
      }
    },

    {
      key: 'transactionDate',
      title: 'Гүйлгээ огноо',
      dataIndex: 'transactionDate',
      width: 125,
      render: (transactionDate: string) => transactionDate.slice(0,10),
    },
    {
      key: 'txnTime',
      title: 'цаг',
      dataIndex: 'txnTime',
    },


    {
      key: 'lvl',
      title: 'LVL',
      dataIndex: 'lvl',
      render: (lvl: Number) => {return (<>{`+ ${lvl}`}</>)}
    },
  ];
  function numberWithCommas(x:any) {
    if(x)
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  return (
    <div>
      {contextHolder}
      <Row gutter={[16, 16]}>
        <Col xs={22} lg={12}>
        {bank && bank.is_active &&(<>
          <div className="card-container px-5 py-7">
       
       
            <Spin spinning={bankLoading && bank.is_active}>
              <>
                <div className="flex justify-between items-center mb-2">
                  <div className="text-lg font-medium">
                    ЦЭНЭГЛЭХ ДАНСНЫ МЭДЭЭЛЭЛ
                  </div>

                </div>
                <div className="mt-5 text-lg text-gray">Банк</div>

                <div className="mt-1" onClick={() => { clipboardwriteText((bank as any).name) }}>
                  <h2> {(bank as any).name} <CopyOutlined /></h2>
                </div>
                <div className="mt-5 text-lg text-gray">Дансны дугаар</div>
                <div className="mt-1" onClick={() => { clipboardwriteText((bank as any).accountNumber) }}>
                  <h2> {(bank as any).accountNumber} <CopyOutlined /></h2>
                </div>
                <div className="mt-5 text-lg text-gray">Дансны нэр</div>
                <div className="mt-1" onClick={() => { clipboardwriteText((bank as any).accountName) }}>
                  <h2> {(bank as any).accountName} <CopyOutlined /></h2>
                </div>
                <div className="mt-5 text-lg text-gray">
                  Гүйлгээний утга
                </div>
                <div className="mt-1" onClick={() => { clipboardwriteText(`${bank.payCode}${melbetID}`) }}>
                  <h2> {(bank as any).payCode}{melbetID} <CopyOutlined /></h2>
                </div>


              </>
           
              <Alert message="Анхааруулга"  description="
              ТА МӨНГӨӨ ШИЛЖҮҮЛЭЭД ДООРХ
ТӨЛБӨР ШАЛГАХ ТОВЧИН ДЭЭР ДАРНА УУ " type="warning" showIcon />
{checkMsg && (
           <h5>{checkMsg}</h5>
)}
      
                  
            </Spin>
      
            <Button className="w-full rounded-xl my-7" type='primary' onClick={checkDeposit}loading={isCheckDepo}> 
            {checkTime>0 && isCheckDepo && (<>{checkTime} {' sec ' }</>)}
            Төлбөр шалгах
            </Button>
          
          </div>
</>)}

{bank && !bank.is_active &&(<>
          <div className="card-container px-5 py-7">
          <Alert message="Анхааруулга"  description="
              Засвартай байгаа тул та дараа дахин оролдоно уу" type="warning" showIcon />
            </div>
            </>
)}
        </Col>
        {config && (
   <Col xs={22} lg={12}>
   <div className="card-container px-5 py-7">
     <Spin spinning={configLoading}>

         <div className="flex justify-between items-center mb-2">
           <div className="text-lg font-medium">
             
           </div>

         </div>
         <div className="mt-5 text-lg text-gray">Нэг удаагийн гүйлгээний  дүнгийн хязгаар</div>

         <div className="mt-1">
           <h2>{numberWithCommas(config.limit)} </h2>
         </div>
         <div className="mt-5 text-lg text-gray">Нийт өдрийн гүйлгээний тоо хязгаар</div>
         <div className="mt-1" >
           <h2> {config.max_depo_per_day} </h2>
         </div>
         <div className="mt-5 text-lg text-gray">LVL</div>
         <div className="mt-1" >
           <h2> {config.lvl}</h2>
         </div>
      


   
     </Spin>
     <Alert message="   Эрх нэмэх"  description={limitDesc} type='info' showIcon />
     <Button className="w-full rounded-xl  my-7" type='primary' target='_blank' href='https://www.facebook.com/mnmelbet/' > 
     Эрх нэмэх
     </Button>
   </div>
 </Col>
        )}
     
      </Row>
      <DataTable
        tableName="Цэнэглэлтийн түүx"
        columns={columns}
        data={deposits}
        loading={depositsLoading}
          
      />
    
    </div>);
};

export default Deposits;
