import React, {useState} from 'react';
import {Button, Input, Modal, Alert, message} from 'antd';
import * as _ from 'lodash';
import { saveData} from '../../helpers/firestore';
// import LogoImage from '../../assets/images/logo.png';
import {firestore} from '../../firebase';
type Props = {
  uid:string;
  open?: boolean;
  toggle: (value: boolean) => void;
};

const AccountAdd: React.FC<Props> = ({uid,open = false, toggle}) => {
  const [name, setName] = useState('');

  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    setName('');

    setLoadingSave(false);
    toggle(false);
  };

  const validate = () => {
 
    if (
      _.isEmpty(name) || name.length!=9 ||
      loadingSave
    )
      return false;
    return true;
  };

  const onSave = async () => {
    if (!validate()) return;
    setLoadingSave(true);
    let result ={
      status:true,
      error:''
    }
    const user = await firestore.collection(`wazsIDs/`).where('melbetID','==',name).get();
    if(user.docs.length ==0){
        saveData(`wazsIDs`,undefined,{

        melbetID:name,
      },true)
      
      const res= saveData(`wazs/${uid}/depositAccounts`,undefined,{
        melbetID:name,

      },true)
     
    }else{
      const errorMsg =`${name} бүртгэлтэй байна! Өөр ID бүртгүүлнэ үү`
      result.status=false
      result.error=errorMsg
      setError(errorMsg)
    }
  
    if (!result.status) {
      setLoadingSave(false);
      messageApi.open({
        type: 'error',
        content: result.error,
      });
    } else {
      onCancel();
      messageApi.open({
        type: 'success',
        content: 'Melbet ID хадгалагдлаа.',
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Melbet ID нэмэх"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key="send"
            loading={loadingSave}
            disabled={!validate()}
            className="rounded-xl"
            type="primary"
            onClick={onSave}
          >
            Хадгалах
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="mt-4 flex justify-center">
            {/* <img alt="logo" src={LogoImage} height={140} width="auto" /> */}
          </div>
          <div className="dialog-content px-8">
          <Alert message="Анхааруулга"  description="
              Personal profile &gt; Account Number id: xxxxxxxxxxx    
              Melbet Монголоор ашигладаг бол: Хувийн профайл &gt;  Дансны дугаар id: xxxxxxxxxxx" type="warning" showIcon />
            <br/> 
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Melbet ID "
              className="mb-3"
              type='number'
              minLength={9}
              maxLength={9}
            />
       
            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AccountAdd;
